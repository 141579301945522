<template>
    <el-dialog :title="!dataForm.id ? '日报上报' : '日报上报'" :close-on-click-modal="false" :modal-append-to-body="false"
        :lock-scroll="false" :visible.sync="visible">
        <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="80px">
            <el-select v-model="dataForm.cUserId" placeholder="请选择要上报的上级用户">
                <el-option v-for="item in dataList" :key="item.userId" :label="item.username" :value="item.userId">
                </el-option>
            </el-select>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="visible = false" type="primary">取消</el-button>
            <el-button :disabled="loading" type="primary" @click="dataFormSubmit()">确定</el-button>
        </span>
        <!--   </div> -->
    </el-dialog>
</template>
  
<script>
import { get } from 'http'

export default {
    data() {
        return {
            visible: false,
            loading: false,
            dataForm: {
                areaCode: '',
                cUserId: '', //选择框
                ids: ''
            },
            dataList: [],
            dataRule: {
                areaCode: [
                    { areaCode: true, message: '行政区域不能为空', trigger: 'blur' }
                ],
            }
        }
    },
    computed: {
        regionId: {
            get() {
                const newregionId = this.dataForm.areaCode;
                if (newregionId !== undefined) {
                    return Number(newregionId);
                } else {
                    return ''
                }
            }
        }
    },
    methods: {
        init(ids, areaCode) {
            this.dataForm.areaCode = areaCode
            this.dataForm.ids = ids
            const params = {
                'gridCode': '1000',
            }
            this.visible = true
            this.$http({
                url: this.$http.adornUrl("/dataflow/olfgridmanage/getTopUserList"),
                method: 'get',
                params: params
            }).then(({ data }) => {
                if (data && data.code === 0) {
                    this.dataList = data.data
                    console.log("data=>" + JSON.stringify(this.dataList))
                } else {
                    this.dataList = []
                    this.$message.error(data.msg)
                }
            }).catch((err) => {
                console.error(err)
            }).finally(() => {
            })
        },
        // 表单提交
        dataFormSubmit() {
            this.$refs['dataForm'].validate((valid) => {
                if (valid) {
                    this.loading = true
                    this.$http({
                        url: this.$http.adornUrl(`/dataflow/olfgridexaminfo/save`),
                        method: 'post',
                        data: this.$http.adornData({
                            // 'mainId': this.dataForm.ids,
                            'ids': this.dataForm.ids,
                            'userId': this.dataForm.cUserId,
                            'areaCode': this.dataForm.areaCode
                        })
                    }).then(({ data }) => {
                        if (data && data.code === 0) {
                            this.$message({
                                message: '操作成功',
                                type: 'success',
                            })
                            this.visible = false
                            this.$emit('refreshDataList')
                        } else {
                            this.$message.error(data.msg)
                        }
                    }).finally(() => {
                        this.loading = false
                    })
                }
            })
        }
    }
}
</script>
  