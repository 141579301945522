<template>
    <div class="box">
        <el-form :inline="true" :model="dataForm" @keyup.enter.native="search()">
            <table class="detailTable_search" style="width: 100%">
                <tr>
                    <td class="titleTd width80px">日期</td>
                    <td class="valueTd">
                        <div class="block">
                            <el-date-picker v-model="dataForm.time" type="daterange" align="right" unlink-panels
                                range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                                @change="changeHandler" :picker-options="pickerOptions">
                            </el-date-picker>
                        </div>
                    </td>
                    <td class="titleTd width80px">日报状态</td>
                    <td class="valueTd" prop="dayStateValue">
                        <el-select v-model="dataForm.dayStateValue" placeholder="请选择" @change="getDayStateValue" clearable>
                            <el-option v-for="item in dayStates" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </td>
                    <td class="titleTd width80px">区域</td>
                    <td class="valueTd">
                        <region-tree :rootId="gridId !== '' ? String(gridId) : String(regionId)"
                            placeholder="请选择区域" v-model="dataForm.regionId"> </region-tree>
                    </td>
                </tr>
            </table>
        </el-form>
        <div class="wxts_msg_search">
            <div class="f_t">
                <el-button-group>
                    <el-button size="mini" @click="search()" type="success" :disabled="dataListLoading">查询</el-button>
                    <!-- <el-button type="danger" @click="addOrUpdateHandle()" :disabled="dataListSelections.length <= 0">
                        批量上报
                    </el-button> -->
                </el-button-group>
            </div>
        </div>
        <el-table :data="dataList" border stripe v-loading="dataListLoading" @selection-change="selectionChangeHandle"
            style="width: 100%;">
            <!-- <el-table-column type="selection" header-align="center" align="center" width="50">
            </el-table-column> -->
            <el-table-column prop="dayName" header-align="center" align="center" label="日报名称">
            </el-table-column>
            <!-- <el-table-column prop="workType" header-align="center" align="center" label="日报类型">
                <template slot-scope="scope">
                    <span v-if="scope.row.workType == '01'">收费</span>
                    <span v-if="scope.row.workType == '02'">发展新用户</span>
                    <span v-if="scope.row.workType == '03'">维护</span>
                    <span v-if="scope.row.workType == '04'">投诉处理</span>
                    <span v-if="scope.row.workType == '05'">常态客户关怀</span>
                    <span v-if="scope.row.workType == '06'">非常态客户关怀</span>
                </template>
            </el-table-column> -->
            <el-table-column prop="tusername" header-align="center" align="center" label="填报人">
            </el-table-column>
            <el-table-column prop="dayDate" header-align="center" align="center" label="填报时间">
            </el-table-column>
            <el-table-column prop="dayState" header-align="center" align="center" label="日报状态">
                <template slot-scope="scope">
                    <span v-if="scope.row.dayState === '01'"><span style="color: black;font-size: 14px;">完成</span></span>
                    <span v-if="scope.row.dayState === '02' && scope.row.percentage === 100"><span
                            style="color:red;font-size: 14px;">待处理<span v-if="scope.row.cuserId!==undefined">(已上报)</span></span></span>
                    <span v-else-if="scope.row.dayState === '02' && scope.row.percentage !== 100"><span
                            style="color: #309DF0;font-size: 14px;">待处理<span v-if="scope.row.cuserId!==undefined">(已上报)</span></span></span>
                    <span v-if="scope.row.dayState === '03'"><span
                            style="color: black;font-size: 14px;">已处理</span></span>
                </template>
            </el-table-column>
            <!-- <el-table-column prop="cusername" header-align="center" align="center" label="处理人">
            </el-table-column>
            <el-table-column prop="dealContent" header-align="center" align="center" label="处理意见">
            </el-table-column>
            <el-table-column prop="dealDate" header-align="center" align="center" label="处理时间">
            </el-table-column> -->
            <el-table-column header-align="center" align="center" width="150" label="操作">
                <!-- <template slot-scope="scope">
                    <el-button v-if="scope.row.cuserId && scope.row.dayState != '02'" class="update-button" type="text"
                        size="small" @click="detailInfo(scope.row)">
                        详情</el-button>
                    <el-button
                        v-if="!scope.row.cuserId && (scope.row.tuserId === currentUserId) && scope.row.dayState == '02'"
                        class="update-button" type="text" size="small"
                        @click="addOrUpdateHandle(scope.row.id, scope.row.areaCode)">
                        上报</el-button>
                    <el-button
                        v-if="scope.row.cuserId && (scope.row.cuserId === currentUserId) && scope.row.dayState == '02'"
                        class="del-button" type="text" size="small" @click="updateHandle(scope.row)">处理</el-button>
                    <el-button
                        v-if="!scope.row.cuserId && scope.row.tuserId && (scope.row.tuserId === currentUserId) && scope.row.dayState == '02'"
                        class="del-button" type="text" size="small" @click="complete(scope.row)">完成</el-button>
                </template> -->
                <template slot-scope="scope">
                    <el-button class="update-button" type="text"
                        size="small" @click="detailInfo(scope.row)">
                        详情</el-button>
                    <!-- <el-button
                        class="update-button" type="text" size="small"
                        @click="addOrUpdateHandle(scope.row.id, scope.row.areaCode)">
                        上报</el-button> -->
                    <el-button v-if="scope.row.dayState === '02'&&scope.row.cuserId!==undefined &&scope.row.cuserId === currentUserId" class="del-button" type="text" size="small"
                        @click="updateHandle(scope.row)">处理</el-button>
                    <!-- <el-button
                                            class="del-button" type="text" size="small" @click="complete(scope.row)">完成</el-button> -->
                    </template>
            </el-table-column>
        </el-table>

        <el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle" :current-page="pageIndex"
            :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="totalCount"
            layout="total, sizes, prev, pager, next, jumper"></el-pagination>

        <!-- 弹窗, 新增 / 修改 -->
        <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
        <!-- 弹窗, 处理 -->
        <update v-if="updateVisible" ref="update" @refreshDataList="getDataList"></update>
        <!-- 弹窗, 详情 -->
        <detailWindow v-if="detailVisible" ref="detailWindow" @refreshDataList="getDataList"></detailWindow>
    </div>
</template>
  
<script>
import { getNewList } from "@/api/kanban/grid";
import AddOrUpdate from './add-or-update';
import update from './update-grid-examinfo';
import detailWindow from './details';
export default {
    data() {
        return {
            dataListLoading: false,
            dataForm: {
                time: [],
                regionId: '',
                startTime: '',
                endTime: '',
                dayStateValue: '' //日报状态
            },
            dayStates: [{
                label: '完成',
                value: '01'
            },
            {
                label: '待处理',
                value: '02'
            },
            {
                label: '已处理',
                value: '03'
            }
            ],
            dataList: [],
            totalCount: 0,
            pageIndex: 1,
            pageSize: 10,
            dataListSelections: [],
            addOrUpdateVisible: false,
            detailVisible: false,
            updateVisible: false,
            currentUserId: '',
            pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近六个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 180);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一年',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
        }
    },

    created() {
        this.currentUserId = this.$store.state.user.id
        console.log(this.currentUserId)
    },

    components: {
        AddOrUpdate, update, detailWindow
    },

    activated() {
        let pageIndex = this.$route.query.pageIndex;
        if (pageIndex) {
            this.pageIndex = parseInt(pageIndex);
        }
        let pageSize = this.$route.query.pageSize;
        if (pageSize) {
            this.pageSize = parseInt(pageSize)
        }
        this.getDataList();
    },
    computed: {
        regionId: {
            get() {
                const newregionId = this.$store.state.user.regionId;
                console.log("regionId=====>" + newregionId)
                if (newregionId !== undefined) {
                    return Number(newregionId);
                } else {
                    return ''
                }
            }
        },
        gridId: {
            get() {
                const newgridId = this.$store.state.user.gridId;
                console.log("gridId=====>" + newgridId)
                if (newgridId !== undefined) {
                    return Number(newgridId);
                } else {
                    return ''
                }
            }
        },
        regionCode: {
            get() {
                return this.$store.state.user.regionCode;
            }
        },
        gridCode: {
            get() {
                return this.$store.state.user.gridCode;
            }
        },
    },

    methods: {
        search() {
            this.pageIndex = 1;
            this.getDataList();
        },
        changeHandler(val) {
            this.dataForm.time = val;
            if (this.dataForm.time != null) {
                this.dataForm.startTime = this.dealdate(this.dataForm.time[0]);
                this.dataForm.endTime = this.dealdate(this.dataForm.time[1]);
            } else {
                this.dataForm.startTime = '';
                this.dataForm.endTime = '';
            }
        },
        getDayStateValue(val) {
            // console.log("val===>"+val)
            this.dataForm.dayStateValue = val;
        },
        //查询获取数据列表
        getDataList() {
            const _this = this;
            this.dataListLoading = true;
            const params_ = {
                'dayDateStart': _this.dataForm.startTime,
                'dayDateEnd': _this.dataForm.endTime,
                'areaCode': _this.dataForm.regionId ? _this.dataForm.regionId : (_this.gridId !== '' ? _this.gridId : _this.regionId),
                'dayState': _this.dataForm.dayStateValue,
                'page': _this.pageIndex,
                'limit': _this.pageSize,
            }
            getNewList(params_).then(({ data }) => {
                if (data && data.code === 0) {
                    _this.dataList = data.data.list
                    console.log("this.dataList=>" + JSON.stringify(_this.dataList))
                    _this.totalCount = data.data.totalCount
                } else {
                    _this.dataList = []
                    _this.totalCount = 0
                    _this.$message.error(data.msg)
                }
            }).catch((err) => {
                console.error(err)
            }).finally(() => {
                _this.dataListLoading = false;
            })
        },
        refresh() {
        },
        // 每页数
        sizeChangeHandle(val) {
            this.pageSize = val
            this.pageIndex = 1
            this.getDataList()
            this.refresh()
        },
        // 当前页
        currentChangeHandle(val) {
            this.pageIndex = val
            this.getDataList()
            this.refresh()
        },
        //多选
        selectionChangeHandle(val) {
            this.dataListSelections = val
            // console.log(this.dataListSelections)
        },

        // 详情
        detailInfo(row) {
            this.detailVisible = true
            this.$nextTick(() => {
                this.$refs.detailWindow.init(row)
            })
        },

        // 上报
        addOrUpdateHandle(id) {
            var idList = this.dataListSelections.map(item => {
                return item.id
            });
            var ids = id ? id : idList.join(',')
            this.addOrUpdateVisible = true
            this.$nextTick(() => {
                this.$refs.addOrUpdate.init(ids, this.$store.state.user.gridId !== undefined ? this.$store.state.user.gridId : this.$store.state.user.regionId)
            })
        },

        // 处理
        updateHandle(row) {
            this.updateVisible = true
            this.$nextTick(() => {
                this.$refs.update.init(row)
            })
        },

        // 完成
        complete(row) {
            // console.log(row)
            const params = {
                'dayState': "01",
                'id': row.id,
            }
            this.$http({
                url: this.$http.adornUrl("/dataflow/olfgridmanage/updateStatrById"),
                method: 'post',
                params: params
            }).then(({ data }) => {
                // console.log("data=====>>>>" + JSON.stringify(data))
                if (data && data.code === 0 && data.data === 1) {
                    this.$message.error("修改成功")
                    this.getDataList()
                } else {
                    this.$message.error("修改失败")
                }
            }).catch((err) => {
                console.error(err)
            }).finally(() => {
            })
        },

        getObjectValues(object) {
            var values = [];
            for (var property in object)
                values.push(object[property]);
            return values;
        },

        // 日期转换
        dealdate(date) {
            const dt = new Date(date)
            const y = dt.getFullYear()
            const m = (dt.getMonth() + 1 + '').padStart(2, '0')
            const d = (dt.getDate() + '').padStart(2, '0')
            return `${y}-${m}-${d}`
        }

    }
}
</script>
  
<style scoped>
.box {
    width: 100%;
    height: 100%;
}

.coverClass {
    margin-top: 40px;
    width: 100%;
    height: 400px;

}

.stateClass {
    width: 100%;
    height: 90%;
    display: flex;
    align-items: center;
}

.b-sousuo {
    display: flex;
}

/* .block{
      margin-right: 10px;
    } */
</style>
  