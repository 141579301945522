<template>
    <el-dialog :title="!dataForm.cUserId ? '网格经理日报审批' : '网格经理上报日报审批'" :close-on-click-modal="false"
        :modal-append-to-body="false" :lock-scroll="false" :visible.sync="visible">
        <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="80px">
            <el-form-item label="处理意见" prop="dealContent" label-width=15%>
                <el-input v-model="dataForm.dealContent" type="textarea" placeholder="请输入处理意见内容" maxlength="200"
                    clearable></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="visible = false" type="primary">取消</el-button>
            <el-button :disabled="loading" type="primary" @click="dataFormSubmit()">确定</el-button>
        </span>
        <!--   </div> -->
    </el-dialog>
</template>
  
<script>
import { get } from 'http'

export default {
    data() {
        return {
            visible: false,
            loading: false,
            dataForm: {
                id: '',
                dealContent: '',
                mainId:'',
            },
            dataRule: {
                dealContent: [
                    { required: true, message: '处理意见不能为空', trigger: 'blur' }
                ],
            }
        }
    },
    computed: {
        regionId: {
            get() {
                const newregionId = this.dataForm.areaCode;
                if (newregionId !== undefined) {
                    return Number(newregionId);
                } else {
                    return ''
                }
            }
        }
    },
    methods: {
        init(row) {
            console.log("row=====>>>>>"+JSON.stringify(row))
            this.dataForm.id = row.ogeId
            this.dataForm.mainId=row.id
            this.visible = true
        },
        // 表单提交
        dataFormSubmit() {
            this.$refs['dataForm'].validate((valid) => {
                if (valid) {
                    this.loading = true
                    this.$http({
                        url: this.$http.adornUrl("/dataflow/olfgridexaminfo/updateById"),
                        method: 'post',
                        data: this.$http.adornData({
                            'ogeId': this.dataForm.id,
                            "dealContent": this.dataForm.dealContent,
                            'dayState': "03",
                            'id': this.dataForm.mainId,
                        })
                    }).then(({ data }) => {
                        if (data && data.code === 0 && data.data > 0) {
                            this.$message({
                                message: '操作成功',
                            })
                            this.visible = false
                            this.$emit('refreshDataList')
                        } else {
                            this.$message.error(data.msg)
                        }
                    }).finally(() => {
                        this.loading = false
                    })
                }
            })
        }
    }
}
</script>
  