import http from '@/utils/httpRequest'

//获取主看板数据
export function getGrid(params){
    return http({
      url: http.adornUrl('/dataflow/olfgridmanage/getGrid'),
      method:'get',
      params:params
    })
  }

//查询日报列表
export function getNewList(params){
    return http({
      url: http.adornUrl('/dataflow/olfgridmanage/newList'),
      method:'get',
      params:params
    })
  }