<template>
    <el-dialog :title="'详情'" :close-on-click-modal="false" :modal-append-to-body="false" :lock-scroll="false"
        :visible.sync="visible">
        <div class="material-view">
            <el-form :model="dataForm" ref="dataForm" :modal-append-to-body="false" label-width="80px">
                <el-form-item label="日报名称:" prop="dayName" label-width=15%>
                    {{ dataForm.dayName }}
                </el-form-item>
                <!-- <el-form-item label="日报类型:" prop="workType" label-width=15%>
                    {{ dataForm.workType }}
                </el-form-item> -->
                <el-form-item label="填报人:" prop="tusername" label-width=15%>
                    {{ dataForm.tusername }}
                </el-form-item>
                <el-form-item label="填报时间:" prop="dayDate" label-width=15%>
                    {{ dataForm.dayDate }}
                </el-form-item>
                <el-form-item label="日报状态:" prop="dayState" label-width=15%>
                    {{ dataForm.dayState }}
                </el-form-item>


                <el-form-item v-for="item in dataForm.olfGridDetailEntitys" :key="item.workType" label="日报类型:"
                    label-width=15%>
                    <el-col>
                        {{ item.typeName }}
                    </el-col>
                    <el-col>
                        <el-input type="textarea" :rows="2" v-model="item.dayWork" readonly>
                    </el-input>
                        <!-- {{ item.dayWork }} -->
                    </el-col>
                  
                    <!--<el-input v-model="item.typeName" style="width: 50%" disabled />-->
                </el-form-item>

 



                <el-form-item label="处理人:" prop="cUsername" label-width=15%>
                    {{ dataForm.cUsername }}
                </el-form-item>
                <el-form-item label="处理意见:" prop="dealContent" label-width=15%>
                    <el-input type="textarea" :rows="2" v-model="dataForm.dealContent" readonly>
                    </el-input>
                    <!-- {{ dataForm.dealContent }} -->
                </el-form-item>
                <el-form-item label="处理时间:" prop="dealDate" label-width=15%>
                    {{ dataForm.dealDate }}
                </el-form-item>
            </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="visible = false" type="primary">关闭</el-button>
        </span>
    </el-dialog>
</template>

<script>
export default {
    data() {
        return {
            visible: false,
            loading: false,
            dataForm: {
                dayName: '',
                workType: '',
                tusername: '',
                dayDate: '',
                dayState: '',
                cUsername: '',
                dealContent: '',
                dealDate: '',
                olfGridDetailEntitys: [],
            }
        }
    },
    methods: {
        init(row) {
            // console.log(row)
            this.dataForm.dayName = row.dayName
            if ('01' == row.workType) {
                this.dataForm.workType = '收费'
            } else if ('02' == row.workType) {
                this.dataForm.workType = '发展新用户'
            }
            else if ('03' == row.workType) {
                this.dataForm.workType = '维护'
            }
            else if ('04' == row.workType) {
                this.dataForm.workType = '投诉处理'
            }
            else if ('05' == row.workType) {
                this.dataForm.workType = '常态客户关怀'
            }
            else if ('06' == row.workType) {
                this.dataForm.workType = '非常态客户关怀'
            }
            this.dataForm.tusername = row.tusername
            this.dataForm.dayDate = row.dayDate
            this.dataForm.dayState = row.dayState
            if ('01' == row.dayState) {
                this.dataForm.dayState = '完成'
            } else if ('02' == row.dayState) {
                this.dataForm.dayState = '待处理'
            }
            else if ('03' == row.dayState) {
                this.dataForm.dayState = '已处理'
            }
            this.dataForm.cUsername = row.cusername
            this.dataForm.dealContent = row.dealContent
            this.dataForm.dealDate = row.dealDate
            this.dataForm.olfGridDetailEntitys = row.olfGridDetailEntitys
            console.log(this.dataForm.olfGridDetailEntitys)
            this.visible = true
        }
    }
}
</script>

<style scoped>

</style>
